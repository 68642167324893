import React from 'react';
import JsonData from "./data/data.json"
import {Navigation, Header, Features, About, Services, Gallery, Testimonials, Team, Contact, Login, Footer} from './components'
import { Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import { singleton, container } from 'tsyringe';
import {GlobalUserService} from './globalServices/userService'
import './App.css';

function Index() {
    const [landingPageData, setLandingPageData] = React.useState<any>({});
    React.useEffect(() => {
        setLandingPageData(JsonData);
    }, []);
    
    return (
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            {/* <Services data={landingPageData.Services} />
            <Gallery data={landingPageData.Gallery} />
            <Testimonials data={landingPageData.Testimonials} />
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />  */}
            <Footer/>
        </div>
    );
}

const App:React.FC = (props)=>{
    React.useEffect(()=>{
        const globalUSerService = container.resolve(GlobalUserService)
        globalUSerService.loadUserFromServer()
    }, [])
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Index/>} />
                <Route path='/login' element={<Login/>} />
            </Routes>
        </Router>
    )
}

export default App;
