import { message } from 'antd';

// promise超时
export const waitWithTimeout = async (promise:Promise<any>, timeout:number, timeoutMessage:string = "timeout") => {
    let timer: any = undefined;
    const timeoutPromise = new Promise((_, reject) => {
      timer = setTimeout(() => reject(timeoutMessage), timeout);
    }); 
    return Promise.race([timeoutPromise, promise])
      .finally(() => clearTimeout(timer))
  }
  
  
const DefaultHeaders = {
    'Accept':'application/json',
    'Content-Type': 'application/json',
    'credentials' : 'include'
}
  
const customRequest = async (netPath:string, method: string, headers:any = DefaultHeaders, 
    body:any='', timeout:number=5000) : Promise<any> => {
    try {
        const respPromise = async() => {
            let options:any = {
                method:method, 
                headers:{...DefaultHeaders, ...headers}
            }
            if(method.toLowerCase()==='post'){
                options = {...options, body:body}
            }
            const respText = await (await fetch(netPath, options)).text()
            return JSON.parse(respText)
        }
        return await waitWithTimeout(respPromise(), timeout)
    } catch (error) {
        console.log('customRequest error', error)
        return {err: error}
    }
}

export const sendGETRequest = async (url:string)=>{
    const ret = await customRequest(url, 'GET')
    return ret 

}

export const sendPOSTRequest = async (url:string, body: any)=>{
    try {
        const bodyStr = JSON.stringify(body)
        const ret =  await customRequest(url, 'POST', null, bodyStr)
        return ret
    } catch (error) {
        return {err: error}
    }
}
