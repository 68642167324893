import React from 'react';
import { Space } from 'antd';


export const Footer: React.FC = ()=>{
    return (
        <div className='footer' style={{
                textAlign: 'center', 
                height: 80, 
                backgroundColor: '#001529',
                display: 'flex'
        }}>
            <Space size={'large'} style={{margin: 'auto'}}>
                <span>
                    <a href="http://beian.miit.gov.cn/">京ICP备2022030815号</a> 
                </span>
                <span>
                <Space>
                    <img src='https://static.sqlzhushou.com/police_record_icon.png'/>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051194">
                        京公网安备 11010502051194号
                    </a> 
                </Space>
                </span>       
            </Space>
        </div>
    )
}