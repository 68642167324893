import React from "react";
import {AvatarView} from './avatar'
import './navigation.less'

export const Navigation: React.FC = (props) => {
  return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{display: 'flex'}}>
            <div className="container">
                <div className='brand-logo'>
                    <img className='logo-img' src='img/SQLHelper.png'></img>
                </div>
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                    {" "}
                    <span className="sr-only">Toggle navigation</span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        SQL助手
                    </a>{" "}
                </div>

                {/* <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#" className="page-scroll">
                                使用教程
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>
            <AvatarView/>
        </nav>
  );
};
