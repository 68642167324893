import React from 'react'
import {Dropdown, Avatar, Menu, Button} from 'antd'
import {
  LogoutOutlined,
  UserOutlined 
} from '@ant-design/icons'
import {GlobalUserService, useGlobalUser} from '../globalServices/userService'
import { container } from 'tsyringe'

const globalUserService = container.resolve(GlobalUserService)

export const AvatarView: React.FC = (props) => {
  const globalUser =  useGlobalUser()
  const onMenuClick = async (event: any) => {
      const { key } = event;
      if (key === 'logout') {
        globalUserService.logoutUser()
      }
    }

  const menuHeaderDropdown = (
    <Menu className='AvatarDropdown-menu' selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key="logout">
          <LogoutOutlined />
          退出登录
        </Menu.Item>
    </Menu>
  )

  return (
    <div>
        {
            globalUser.uid === null &&
            <Button
                type="primary"
                onClick={()=>{window.location.href = '/login?from=avatar'}}
            >
                登录
            </Button>
            
        }
        {
            globalUser.uid !== null &&
            <Dropdown overlay={menuHeaderDropdown}>
                <span className='avatar-span'>
                  {
                    globalUser.avatorUrl &&
                    <Avatar
                      size="default"
                      className='avatar'
                      src={globalUser.avatorUrl}
                      alt="avatar"
                    />

                  }
                  {
                    !globalUser.avatorUrl &&
                    <Avatar
                      size="default"
                      className='avatar'
                      icon={<UserOutlined />}
                      style={{ backgroundColor: '#87d068' }}
                    />
                  }
                </span>
            </Dropdown>
        }
    </div>
  );
};