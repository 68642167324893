import React from 'react'
import {
    LockOutlined,
    MobileOutlined
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormCaptcha,
    ProFormText,
} from '@ant-design/pro-components';
import {message, Tabs } from 'antd';
import { useState } from 'react';
import {sendPOSTRequest} from '../api'
import {EasySQLServerLocation, getCookieValue, WxLoginRedirectUrl} from '../common'  
import WxLogin from './wxlogin'
import './login.less'

type LoginType = 'phone' | 'wx';

const WX_APP_ID = 'wxb8f799505c2c2e40'

export const Login:React.FC = () => {
    const [loginType, setLoginType] = useState<LoginType>('wx')
    const onFinish = async (values: any) =>{
        const {mobile, captcha} = values
        try {
            const respData = await sendPOSTRequest('/api/login/', {uid: mobile, auth_code:captcha})
            if (respData.result === 'success'){
                message.success('登录成功')
                const url = new URL(document.location.href)
                const from = url.searchParams.get('from')
                if(from){
                    const uid = getCookieValue('uid')
                    const token = getCookieValue('token')
                    if(from === 'start'){
                        window.location.href = '/'
                        if(uid !== null && token !== null){
                            window.open(EasySQLServerLocation + `?uid=${mobile}&token=${token}`, '_blank')
                        }
                        else {
                            window.open(EasySQLServerLocation, '_blank')
                        } 
                    }
                    else if (from === 'avatar'){
                        window.location.href = '/'
                    }
                    else if (from === 'EasySQL'){
                        if(uid !== null && token !== null){
                            window.location.href = EasySQLServerLocation + `?uid=${mobile}&token=${token}`
                        }
                        else {
                            window.location.href = EasySQLServerLocation
                        }
                    }
                }
                else {
                    window.location.href = '/'
                }
            }
            else {
                console.log('LoginPage onFinish result fail', respData.result)
                message.error('登录失败, 请检查密码是否正确')
            }
        } catch (error) {
            console.log('LoginPage onFinish error', error)
        }
    }
    
    React.useEffect(()=>{
        const asyncFunc = async ()=>{
            const url = new URL(document.location.href)
            const withWxCode = url.searchParams.get('with_wx_code')
            const wxCode = url.searchParams.get('code')
            if(withWxCode === 'true' && wxCode){
                try {
                    const respData = await sendPOSTRequest('/api/login/', {wxCode: wxCode})
                    if (respData.result === 'success' && respData.uid){
                        message.success('登录成功')
                        window.location.href = '/'
                    }
                } catch (error) {
                    console.log('LoginPage withWxCode error', error)
                }
            }
        }
        asyncFunc()
    }, [])
    return (
        <div style={{ backgroundColor: 'white', height: 'calc(100vh - 48px)', margin: -24 }}>
        <LoginForm
            logo="/img/SQLHelper.png"
            title="SQL助手"
            subTitle="让数据开发更简单"
            actions={
            <div
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                }}
            >
                {/* <Divider plain>
                <span style={{ color: '#CCC', fontWeight: 'normal', fontSize: 14 }}>
                    其他登录方式
                </span>
                </Divider> */}
                {/* <Space align="center" size={24}>
                <div
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 40,
                    width: 40,
                    border: '1px solid #D4D8DD',
                    borderRadius: '50%',
                    }}
                >
                    <AlipayOutlined style={{ ...iconStyles, color: '#1677FF' }} />
                </div>
                <div
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 40,
                    width: 40,
                    border: '1px solid #D4D8DD',
                    borderRadius: '50%',
                    }}
                >
                    <TaobaoOutlined style={{ ...iconStyles, color: '#FF6A10' }} />
                </div>
                <div
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: 40,
                    width: 40,
                    border: '1px solid #D4D8DD',
                    borderRadius: '50%',
                    }}
                >
                    <WeiboOutlined style={{ ...iconStyles, color: '#333333' }} />
                </div>
                </Space> */}
            </div>
            }
            onFinish={onFinish}
        >
            <Tabs
            centered
            activeKey={loginType}
            onChange={(activeKey) => setLoginType(activeKey as LoginType)}
            >
            <Tabs.TabPane key={'wx'} tab={'微信扫码登录'} />
            <Tabs.TabPane key={'phone'} tab={'短信验证码登录'} />
            </Tabs>
            {loginType === 'wx' && (
                <WxLogin
                    appId={WX_APP_ID}
                    redirectUrl={WxLoginRedirectUrl}
                />
            )}
            {loginType === 'phone' && (
            <>
                <ProFormText
                fieldProps={{
                    size: 'large',
                    prefix: <MobileOutlined className={'prefixIcon'} />,
                }}
                name="mobile"
                placeholder={'手机号'}
                rules={[
                    {
                    required: true,
                    message: '请输入手机号！',
                    },
                    {
                    pattern: /^1\d{10}$/,
                    message: '手机号格式错误！',
                    },
                ]}
                />
                <ProFormCaptcha
                    fieldProps={{
                        size: 'large',
                        prefix: <LockOutlined className={'prefixIcon'} />,
                    }}
                    captchaProps={{
                        size: 'large',
                    }}
                    placeholder={'请输入验证码'}
                    captchaTextRender={(timing, count) => {
                        if (timing) {
                        return `${count} ${'获取验证码'}`;
                        }
                        return '获取验证码';
                    }}
                    phoneName="mobile"
                    name="captcha"
                    rules={[
                        {
                        required: true,
                        message: '请输入验证码！',
                        },
                    ]}
                    onGetCaptcha={async (mobile: string) => {
                        try {
                            const respData = await sendPOSTRequest('/api/send_sms_code/', {uid: mobile})
                            if(respData.result === 'success'){
                                message.success('发送验证码成功!')
                            }
                            else{
                                message.error('发送验证码失败: 发送频率过高')
                            }
                        } catch (error) {
                            console.log('send sms code error', mobile, error)
                            message.error('发送验证码失败: 发送频率过高')
                        }
                    }}
                />
            </>
            )}
            {/* <div
            style={{
                marginBlockEnd: 24,
            }}
            >
            <ProFormCheckbox noStyle name="autoLogin">
                自动登录
            </ProFormCheckbox>
            <a
                style={{
                float: 'right',
                }}
            >
                忘记密码
            </a>
            </div> */}
        </LoginForm>
        </div>
    );
};