export const EasySQLServerLocation = 'http://editor.sqlzhushou.com/'
export const EasySQLDocLocation = 'http://doc.sqlzhushou.com/'
export const WxLoginRedirectUrl = 'http://sqlzhushou.com/login?with_wx_code=true'


export const getCookieValue = (name:string) => {
    const cookies = document.cookie.split(';')
    for (let cookie of cookies){
        const cookieSplit = cookie.split('=')
        if(cookieSplit.length === 2 && cookieSplit[0].trim() === name){
            return cookieSplit[1].trim()
        }
    }
    return null 
}