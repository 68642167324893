import 'reflect-metadata';
import { singleton, container } from 'tsyringe';
import molecule from '@dtinsight/molecule'
import React from 'react'
import {sendGETRequest, sendPOSTRequest} from '../api'

const Component = molecule.react.Component

// define model 
export interface IGlobalUser {
    uid: string | null,
    avatorUrl: string | null,
    name: string | null
}

// 
export class GlobalUserModel implements IGlobalUser {
    uid: string | null
    avatorUrl: string | null;
    name: string | null;
    constructor() {
        this.uid = null 
        this.avatorUrl = null
        this.name = null
    }
}

export interface IGlobalUserService extends molecule.react.Component<GlobalUserModel> {
    loadUserFromServer: ()=>Promise<void>
    logoutUser: ()=>void
}

@singleton()
export class GlobalUserService
    extends Component<IGlobalUser>
    implements IGlobalUserService
{
    protected state: IGlobalUser;

    constructor() {
        super();
        this.state = container.resolve(GlobalUserModel);
    }

    loadUserFromServer = async () =>{
        try {
            const respData = await sendGETRequest('/api/get_user/')
            if(respData.result && respData.result.uid){
                let state = {
                    uid: respData.result.uid,
                    name: null,
                    avatorUrl: null
                } 
                if(respData.result.userInfo?.nickname){
                    state.name = respData.result.userInfo.nickname
                }
                if(respData.result.userInfo?.headimgurl){
                    state.avatorUrl = respData.result.userInfo.headimgurl
                }
                this.setState(state)
            }
        } catch (error) {
            console.log('loadUserFromServer error:', error)
        }
    }

    logoutUser = async ()=>{
        try {
            await sendGETRequest('/api/logout/')
        } catch (error) {
            console.log('GlobalUserService logoutUser error', error)
        }
        this.setState({
            uid:null
        })
    }
}

export const useGlobalUser = ()=>{
    const globalUserService = container.resolve(GlobalUserService)
    const [globalUser, setGlobalUser] = React.useState<IGlobalUser>(globalUserService.getState())
    React.useEffect(()=>{
        globalUserService.onUpdateState((prevState, nextState)=>{
            setGlobalUser({...nextState})
        })
    }, [])
    return globalUser
}