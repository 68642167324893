import React from 'react'

export interface IWxLoginProps {
    appId: string,
    redirectUrl: string
}

const WxLogin: React.FC<IWxLoginProps> = (props)=>{
    const [appId, setAppId] = React.useState<string>(props.appId)
    const [redirectUrl, setRedirectUrl] = React.useState<string>(props.redirectUrl)

    React.useEffect(()=>{
        setAppId(props.appId)
    }, [props.appId])

    React.useEffect(()=>{
        setRedirectUrl(props.redirectUrl)
    }, [props.redirectUrl])

    React.useEffect(()=>{
        const wxLoginObj = new window.WxLogin({
            self_redirect: false,
            id:"wx-login", 
            appid: appId, 
            scope: "snsapi_login", 
            redirect_uri: encodeURIComponent(redirectUrl),
            state: "",
            style: "",
            href: ""
        })
    }, [])
    return (
        <div id='wx-login'>
        </div>
    )
}

export default WxLogin