import React from "react";
import {EasySQLServerLocation} from '../common'
import {GlobalUserService} from '../globalServices/userService'
import { singleton, container } from 'tsyringe';
import {EasySQLDocLocation, getCookieValue} from '../common'
import GithubButton from './GithubButton'

interface IProps {
    data: {
        title: string,
        paragraph:string
    }
}

export const Header: React.FC<IProps> = (props) => {
    const onStartClick = () =>{
        const globalUserService = container.resolve(GlobalUserService)
        const {uid} = globalUserService.getState()
        if(uid === null){
            window.location.href = '/login?from=start'
        }
        else {
            const uid = getCookieValue('uid')
            const token = getCookieValue('token')
            if(uid !== null && token !== null){
                window.open(EasySQLServerLocation + `?uid=${uid}&token=${token}`, '_blank') 
            }
            else {
                window.location.href = EasySQLServerLocation
            }
        }
    }
    const onTutorialClick = ()=>{
        window.open(EasySQLDocLocation, '_blank') 
    }
    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 intro-text">
                        <h1>
                            {props.data ? props.data.title : "Loading"}
                            <span></span>
                        </h1>
                        <p>{props.data ? props.data.paragraph : "Loading"}</p>
                        <button
                            className="btn btn-custom btn-lg page-scroll"
                            onClick={onStartClick}
                        >
                            开始使用
                        </button>
                        <button
                            className="btn btn-custom-second btn-lg page-scroll"
                            onClick={onTutorialClick}
                            style={{margin: 20}}
                        >
                            使用文档
                        </button>
                        <GithubButton url="https://github.com/youqiang95/DAG-SQL-Builder"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </header>
    );
};