import React from 'react';
import { Button, Tooltip } from "antd";
import {GithubOutlined} from '@ant-design/icons'


const GitHubButton: React.FC<{ url: string }> = ({ url }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Tooltip title="github">
        <Button 
            size={'large'}
            shape="circle"
            icon={<GithubOutlined/>}
            onClick={handleClick} 
        />
    </Tooltip>
  );
};

export default GitHubButton;